<script context="module">
import {gsap} from "gsap"

import {ScrollTrigger} from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export function GsapAnimation() {
    gsap.to(".preload-logo, .preload-bg", {
        delay: 0.25,
        duration: 1.6,
        scale: 1,
        height: 0,
        skewX: 0,

        ease: "power3.inOut",
        // stagger: {
        //     amount: 0.5,
        // },
    })
    gsap.from(".contact-bg", {
        delay: 0.95,

        duration: 1,
        scale: 1.1,
        width: 0,
        skewX: 2,
        ease: "power3.inOut",
        // stagger: {
        //     amount: 0.3,
        // },
    })

    gsap.defaults({ease: "slow"})

    gsap.set(".wrap ", {
        duration: 0.2,

        opacity: 0,
        skewY: 0,
        y: 25,
   

    })
    ScrollTrigger.batch(".wrap", {
        onEnter: batch =>
            gsap.to(batch, {
                opacity: 1,
                y: 0,
                skewX: 0,
                duration: 0.1,
                // overwrite: true,
                    scrollTrigger: {
                        trigger: batch,
                start: "85% 85%",
                end: "70% 70%",
                scrub: 2.5,
                },
            }),
    })

    gsap.set(".fadein ", {duration: 0.5, opacity: 0, y: -50})
    ScrollTrigger.batch(".fadein ", {
        onEnter: batch =>
            gsap.to(batch, {
                opacity: 1,
                y: 0,
                duration: 0.4,
                stagger: 0.85,
                overwrite: true,
            }),
        // start: "10% 75%",
    })
    gsap.set(".line ", {duration: 0.5, opacity: 1, width: 0})
    ScrollTrigger.batch(".line ", {
        onEnter: batch =>
            gsap.to(batch, {
                opacity: 1,
                width: "100%",
                duration: 1,
                stagger: 0.05,
                scrollTrigger: {
                        trigger: batch,
                start: "85% 85%",
                end: "70% 70%",
                scrub: 0.5,
                },
                overwrite: true,
            }),
        // start: "10% 75%",
    })

    ScrollTrigger.batch(".banner-bg2", {
        onEnter: batch =>
            gsap.to(batch, {
                start: "55% 70%",

                end: "bottom bottom",
                opacity: "1",
                // markers: true,
                stagger: 0.09,
                scrub: true,
                overwrite: true,
            }),

        // overwrite: true,
    })

    const pinnedCards = gsap.utils.toArray(".overlap")

    pinnedCards.forEach(project => {
        const scaleCard = gsap.to(project, {
            // scale: ".85",
            transformOrigin: "center top",
        })

        ScrollTrigger.create({
            animation: scaleCard,
            trigger: project,
            start: () => "center top",
            scrub: true,
            anticipatePin: 1,
        })
    })
    const pinnedSkills = gsap.utils.toArray(".overlapsk")

    pinnedSkills.forEach(project => {
        const scaleCard = gsap.to(project, {
            // scale: ".15",
            opacity: "0",
            transformOrigin: "center center",
        })

        ScrollTrigger.create({
            animation: scaleCard,
            trigger: project,
            start: () => "center center",
            scrub: true,
            anticipatePin: 1,
            // pin: true,
            stagger: 0.09,
            opacity: "1",
            scrub: 10.5,

        })
    })
}
</script>
