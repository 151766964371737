<style global>
@import "../styles/globals.scss";

:global(body) svg {
    fill: rgb(255, 255, 255);
}

:global(body.dark-mode) svg {
    fill: rgb(0, 0, 0);
}
svg {
    width: clamp(4.75rem, 10.5vw, 18rem);
    height: clamp(4.75rem, 10.5vw, 18rem);
}
</style>

<svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    style="enable-background:new 0 0 20 20"
    viewBox="0 0 20 20"

    ><path
        d="M10 7c-.8 0-1.6.3-2.1.9S7 9.2 7 10s.3 1.6.9 2.1 1.3.9 2.1.9 1.6-.3 2.1-.9.9-1.3.9-2.1-.3-1.6-.9-2.1S10.8 7 10 7zm0-2c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.5 3.5-.9 1-2.2 1.5-3.5 1.5-1.3 0-2.6-.5-3.5-1.5-1-.9-1.5-2.2-1.5-3.5 0-1.3.5-2.6 1.5-3.5C7.4 5.5 8.7 5 10 5zm6.5-.3c0 .3-.1.6-.4.9-.2.3-.5.4-.9.4s-.6-.1-.9-.4c-.2-.2-.3-.5-.3-.9s.1-.6.4-.9c.2-.2.6-.4.9-.4s.6.1.9.4.3.6.3.9zM10 2c-2.5 0-2.9 0-4 .1-.8 0-1.3.1-1.8.3-.5.2-.8.4-1.1.7-.3.3-.6.7-.7 1.1-.2.5-.3 1-.3 1.8C2 7.1 2 7.5 2 10s0 2.9.1 4c0 .8.1 1.3.3 1.8.2.4.4.7.7 1.1.3.3.6.5 1.1.7.5.2 1 .3 1.8.3 1.1.1 1.5.1 4 .1s2.9 0 4-.1c.8 0 1.3-.1 1.8-.3.4-.2.7-.4 1.1-.7.3-.3.5-.6.7-1.1.2-.5.3-1 .3-1.8.1-1.1.1-1.5.1-4s0-2.9-.1-4c0-.8-.1-1.3-.3-1.8-.2-.4-.4-.8-.7-1.1-.3-.3-.7-.6-1.1-.7-.5-.2-1-.3-1.8-.3-1.1-.1-1.5-.1-4-.1zm0-2c2.7 0 3.1 0 4.1.1 1.1.1 1.8.2 2.4.5.7.3 1.2.6 1.8 1.2.5.5.9 1.1 1.2 1.8.2.6.4 1.4.5 2.4v4c0 2.7 0 3.1-.1 4.1-.1 1.1-.2 1.8-.5 2.4-.3.7-.6 1.3-1.2 1.8-.5.5-1.1.9-1.8 1.2-.6.2-1.4.4-2.4.5h-4c-2.7 0-3.1 0-4.1-.1-1.1-.1-1.8-.2-2.4-.5-.7-.3-1.3-.6-1.8-1.2-.5-.5-.9-1.1-1.2-1.8C.3 15.8.1 15 0 14v-4c0-2.7 0-3.1.1-4.1.1-1.1.2-1.8.5-2.4.3-.7.6-1.3 1.2-1.8.5-.5 1.1-.9 1.8-1.2C4.2.3 5 .1 6 0h4z"
    ></path></svg
>
