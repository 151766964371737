<style lang="scss" global>
:global(body) svg {
    fill: rgb(255, 255, 255);
}
// :global(body.dark-mode) svg {
//     fill: rgb(0, 0, 0);
// }
svg:hover {
    fill: rgb(111, 76, 238);
}

.svg1{
    width:  clamp(11.75rem, 20.5vw, 19rem);

// overflow: hidden;
    position: absolute;
    right:var(--space-3xl);
    bottom: var(--space-l);
    background-color: tomato;
    border-radius: 50%;


//scale: 1.2;
z-index: -1;
text-transform: uppercase;

}
.svg2{
    width:  clamp(11.75rem, 20.5vw, 19rem);
    // overflow: hidden;

    position: absolute;
    right:var(--space-3xl);


    bottom: var(--space-l);
    // background-color: tomato;
    border-radius: 50%;
// padding: .3rem;
z-index: -1;
text-transform: uppercase;
// padding: 2rem;
}

</style>
<script>
import gsap from "gsap"

import {onMount} from "svelte"
onMount(() => {
    const tl = gsap.timeline()

    tl.from(['.svg2','.svg1'], {
        delay: .6,
        // scale: 0.2,
        opacity: 0,
        duration: 1.5,
        ease: "power2.inOut",


    })
    // tl.fromTo(
    //     ".svg2",
    //     {
    //         opacity: 1,
    //         scale: 1,
    //         ease: "linear",
    //         transformOrigin: "50%",
    //         rotation: 0,
    //     },
    //     {
    //         delay: 1,
    //         opacity: 1,
    //         scale: 0.9,
    //         duration: 1,
    //         rotation: "+=80",
    //         // backgroundColor: "yellow",
    //     },
    // )

})
</script>

<svg
    xmlns="http://www.w3.org/2000/svg"
    xml:lang="en"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
    class="svg1"
>
    <title>Circular Text Path</title>
    <style>
    text {
        font-size: clamp(3rem, 10.5vw, 2.7rem);
        /* font-variant: small-caps; */
        font-variant-ligatures: none;
    }
    </style>
    <defs>
        <path
            id="textcircle"
            d="M250,400
 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
            transform="rotate(22,250 250)"></path>
    </defs>
    <!-- <circle cx="50%" cy="50%" r="260px"></circle> -->
    <!-- <circle cx="50%" cy="50%" r="100px"></circle> -->

    <text dy="-30">
        <textPath
            xlink:href="#textcircle"
            aria-label="All for One &amp; One for All"
            textLength="790"
        >
            •Front-End Developer• 
        </textPath>
    </text>
 
</svg>
<svg
    xmlns="http://www.w3.org/2000/svg"
    xml:lang="en"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
    class="svg2"
>
    <title>Circular Text Path</title>
    <style>
    text {
        /* font-size: 60px; */
        /* font-variant: small-caps; */
        font-variant-ligatures: none;
    }
    </style>
    <defs>
        <path
            id="textcircle"
            d="M250,400
 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
            transform="rotate(22,250 250)"></path>
    </defs>

    <text dy="50">
        <textPath
            xlink:href="#textcircle"
            aria-label="All for One &amp; One for All"
            textLength="790"
        >
            •UI/UX Design•
        </textPath>
    </text>
    
</svg>


