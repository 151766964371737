<style global>
	@import "../styles/globals.scss";
	
		:global(body)  svg {
			fill: rgb(255, 255, 255);
	}
	
	:global(body.dark-mode) svg {
		fill: rgb(0, 0, 0);
	}
	svg{
		width:clamp(4.75rem, 10.5vw, 18rem);
    height:clamp(4.75rem, 10.5vw, 18rem);
	}
</style>

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="170" height="170"
	 viewBox="0 0 20 18" style="enable-background:new 0 0 20 18;" xml:space="preserve">
<path d="M4,2c0,0.5-0.2,1-0.6,1.4C3,3.8,2.5,4,2,4C1.5,4,1,3.8,0.6,3.4C0.2,3,0,2.5,0,2c0-0.5,0.2-1,0.6-1.4C1,0.2,1.5,0,2,0
	c0.5,0,1,0.2,1.4,0.6C3.8,1,4,1.5,4,2L4,2z M4.1,5.5h-4V18h4V5.5z M10.4,5.5h-4V18h3.9v-6.6c0-3.7,4.8-4,4.8,0V18h3.9v-7.9
	c0-6.2-7.1-5.9-8.7-2.9L10.4,5.5L10.4,5.5z"/>
</svg>
