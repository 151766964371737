<style>

  .wordSplit {
    display: inline-block; 
    margin-right: 0.5rem;
  }


.wrapper {
    width: min(100%, 1164px);
    /* margin: 0 auto; */

    color: rgb(255, 255, 255);
    font-weight: 800;
    font-size: clamp(1.75rem, 4.5vw, 3rem);

    line-height: var(--space-l);
}
</style>

<script>
import {onMount} from "svelte"
import {gsap} from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

let paragraphs = [
    "Build websites clearly communicates with visitors.",
    "I use ReactJS to build awesome applications, incorporating efficient and scalable solutions.",
]

// Function to split text into words and wrap each word with a span
function splitText(text) {
    return text
        .split(" ")
        .map(wordSplit => `<span class="wordSplit">${wordSplit}</span>`)
        .join(" ")
}

onMount(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.timeline({
        scrollTrigger: {
            trigger: ".wrapper",
            start: "5% 90%",
            end: "40% 60%",

            pin: false,
            scrub: true,
            // markers: true,
        },
    }).from(".wordSplit", {opacity: 0.2, stagger: 0.5, color: "#000"})
})
</script>

<div class="wrapper">
    {#each paragraphs as paragraph}
        <div>{@html splitText(paragraph)}</div>
    {/each}
</div>
