<script context="module">
import {gsap} from "gsap"

export function GsapHomeAnimation() {



    gsap.set("#patternId", {duration: 1,
        
        // backgroundColor: "rgb(208, 255, 0)",
    }),
        gsap.to("#patternId", {
            duration: 105,
            ease: "expo.out",
            opacity: "1",
            
            backgroundColor: "rgb(111, 76, 238)",
            scrollTrigger: {
                start: "30% 10%",
                trigger: "#patternId",
                end: "bottom top",
                 opacity: '1',
                // markers: true,
                stagger: 0.059,
                scrub: true,
                overwrite: true,
            },
           
        })

//         gsap.to("#patternId", {
//     duration: 1.5,
//     yPercent: -2,
//     repeat: -1,
//     yoyo: true,
//     ease: "sine.inOut"
// });

        gsap.set(".svg2", {transformOrigin: "center center"}),
        gsap.to(".svg2", {
            rotation: 100,
            ease: "linear",
            scrollTrigger: {
                // trigger: ".svg2",
                start: "100% 100%",
                end: "100% 0%",
                // markers: true,

                scrub: true,
            },
        })

        gsap.to(".svg1", {
            rotation: -180,
            ease: "linear",
            scrollTrigger: {
                // trigger: ".svg2",
                start: "100% 100%",
                end: "100% 0%",
                // markers: true,

                scrub: true,
            },
        })


      
}
</script>
