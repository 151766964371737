<style lang="scss" global>
@import "../styles/style.scss";
@import "../styles/globals.scss";
@import "../styles/SocialMedia.scss";
:global(body.dark-mode) .sociallink {
    color: rgb(0, 0, 0);
}
:global(body) .sociallink {
    color: rgb(255, 255, 255);
    margin: 5px;
}
</style>

<script>
</script>

<div class="socialmedia">
    <div class="socialmedia__links">
        <a
            href="https://www.linkedin.com/in/ghofranebenhmaid/"
            class="sociallink"
            target="_blank"
            rel="noreferrer"
        >
            LinkedIn
        </a>
        <a
            href="https://www.instagram.com/_ghofranebenhmaid/"
            class="sociallink"
            target="_blank"
            rel="noreferrer"
        >
            Instagram
        </a>
        <a
            href="https://github.com/ghofranebenhmaid"
            class="sociallink"
            target="_blank"
            rel="noreferrer"
        >
            GitHub
        </a>
        <a
            href="https://www.behance.net/ghofranebenhmaid"
            class="sociallink"
            target="_blank"
            rel="noreferrer"
        >
            Behance
        </a>
    </div>
</div>
