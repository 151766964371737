<style lang="scss" global>
@import "../styles/globals.scss";
@import "../styles/Home.scss";

:global(body) h2,
ul,
h1 {
    color: rgb(255, 255, 255);
}

:global(body.dark-mode) .about-col{
    @media (min-width: 1024px){

        border: 2px solid black;
    }

}
:global(body.dark-mode) h2,
ul,
h1 {
    color: rgb(0, 0, 0);
}
:global(body)
li {
    color: rgb(255, 255, 255);
}

.paragraph {
    z-index: -2;
}

h2 {
    font-size: var(--step-4);
    margin-bottom: var(--space-m);
    overflow: hidden;
}
h3 {
    font-size: var(--step-1);
    margin-bottom: var(--space-xs);
    overflow: hidden;
}

.paragraph,
li,
p {
    // font-size: var(--step-0);
    font-size: 1.1rem;
    margin-bottom: var(--space-m);
    line-height: 1.5;
    // line-height: var(--space-m);
        font-weight: 400;
}
ul {
    width: min(100%, 900px);
    padding-left: 3.5rem;

    li {
        font-size: 1.1rem;

        // line-height: var(--space-s);
        line-height: 1.5;
        font-weight: 400;
        &::marker {
            color: rgba(111, 76, 238, 0.76);
            font-size: 35px;
        }
    }
}

.project__content--description {
    h3 {
        font-size: var(--step-3);
        color: #000;
    }
    p {
        font-weight: 400;
        color: #000;

        // text-wrap: balance;
    }
}

:global(body.dark-mode) .technologi p {
    border: 1px solid rgb(0, 0, 0);
}
:global(body.dark-mode) .card__content {
    background-color: #000;
}
:global(body.dark-mode) .card {
    &__title,
    &__subtitle {
        color: #fff;
    }
    &__email {
        background-color: #fff;
        color: #000;
        padding: 1rem 2rem;
        border-radius: 50px;
    }
}
:global(body) .card__content {
    background-color: #fff;
}
:global(body) .card {
    &__title,
    &__subtitle {
        color: #000;
    }
    &__email {
        background-color: #000;
        color: #fff;
        padding: 1rem 2rem;
        border-radius: 50px;
        &:hover {
            transform: scale(1.1);
        }
    }
}
.primary-button {
    margin-top: var(--space-l);
}
.bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    top: 0;
    left: 0;
    z-index: 10000;
}
hr {
    margin: 5rem 0;
}

.preload-bg {
    // background-color: var( --color-background);
    background-color: rgb(111, 76, 238);
    position: fixed;
    inset: 0;
    z-index: 10000;
    display: grid;
    place-items: center;

    & .preload-logo {
        // svg {
        //     width: 100px;
        //     color: red;
        // }
        // mix-blend-mode: difference;
        // opacity: 0.4;
        // @media (max-width: 768px) {
        //     width: 70px;
        // }
    }
}

.racesWrapper {
// margin-bottom:var(--space-m);
margin:  5rem 0;

width: 100vw;
}
.races {
    // width:fit-content;

    display: flex;
    flex-wrap: nowrap;
    gap: 2rem;
    padding-left: 5.3em;
}

.races h2 {
    flex-shrink: 0;
    font-size: clamp(2.75rem, 9.5vw, 15rem);
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover{
rotate: -5deg;
transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
scale: 1.05;

    }

}

.box {
    background-color: tomato;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover{
rotate: -5deg;
transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
scale: 1.05;

    }
}

 .home {
    overflow-x: hidden;

    // height: 50vh;

}

.profileImg{
    width: clamp(6.75rem, 7.5vw, 17rem);
    aspect-ratio: 1 / 1;
border-radius: 50%;
margin-bottom:var(--space-m);

}
</style>

<script>
import Accordion from "../components/AccordionList.svelte"

let ImageProfile = "../assets/image/bg-profile.jpg"

import {GsapHomeAnimation} from "../components/GsapHomeAnimation.svelte"
import {GsapAnimation} from "../components/GsapAnimations.svelte"
import {onMount} from "svelte"

import {fade, scale} from "svelte/transition"
import {tweened} from "svelte/motion"
import {cubicOut} from "svelte/easing"
import Header from "../components/header/Header.svelte"
import SplitText from "../components/SplitText.svelte"
import Hero from "../components/header/Hero.svelte"
import Footer from "../components/Footer.svelte"
import NavBar from "../components/NavBar.svelte"

const carouselPhotos = [
    "/assets/web/a6.jpg",
    "/assets/web/a5.jpg",
    "/assets/web/a4.jpg",
    "/assets/web/a3.png",
    "/assets/web/a2.png",
]
const carouselPhotos1 = [
    "/assets/web/b1.png",
    "/assets/web/b2.png",
    "/assets/web/b3.png",
    "/assets/web/b4.png",
    "/assets/web/b5.png",
]
let index = 0
const duration = 1300
const opacity = tweened(1, {duration, easing: cubicOut})

const next = () => {
    index = (index + 1) % carouselPhotos.length
    opacity.set(0).then(() => {
        opacity.set(1)
    })
}

setInterval(next, duration)

onMount(() => {
    GsapHomeAnimation()
    GsapAnimation()
})

onMount(() => {
    //     gsap.to(".preload-bg", { keyframes: [
    //   { duration: 2.5, width: "100%" },
    //   { duration: 0, backgroundColor: "#f38630", delay: 0.5 },
    //   { duration: 1, width: 0,  delay: 0.5 }
    // ]});
})
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import LinkedinIcon from "../components/LinkedinIcon.svelte"
import GithubIcon from "../components/GithubIcon.svelte"
import InstagramIcon from "../components/InstagramIcon.svelte"

import AnimatedText from "../components/AnimatedText.svelte"
import LogoArbB from "../components/LogoArbB.svelte"
import Slides from "../components/Slides.svelte"

gsap.registerPlugin(ScrollTrigger)

let races

onMount(() => {

    function getScrollAmount() {
        let racesWidth = races.scrollWidth
        return -(racesWidth - window.innerWidth)
    }

    const tween = gsap.to(races, {
        x: getScrollAmount,
        duration: 3,
        ease: "none",

        anticipatePin: 41,
        // smooth: 10,
        skewX: -15,
    })

    ScrollTrigger.create({
        trigger: ".racesWrapper",
        start: "center 100%",
        end: () => `+=${getScrollAmount() * -1}`,
        //   pin: true,
        animation: tween,
        scrub: 4,
        


        invalidateOnRefresh: true,
        // markers: true,
    })
})
</script>

<!-- <svelte:window bind:scrollY="{y}" /> -->

<NavBar />

<!-- <div class="preload-bg">
    <figure class="preload-logo">
        <LogoArbB className="preload-logo" /> 
    </figure>
</div> -->

<!-- <Header/> -->
    
    <Hero />
    

          
    <!-- <div class="hero container" id="hero">
        <div class="hero__image ">
            <div id="bannerBgAddition" class="banner-addition-bg"></div>
            <div id="bannerBg" class="banner-bg"></div>

            <img id="bannerBg2" class="banner-bg" src="{ImageProfile} " alt="banner bg" />
        </div>
    </div> -->

    <!-- <article class="container-900">
        
        <AnimatedText/>
    </article>  -->
    <article  class="container columns m-top">

        <div class="about-col">

           <div class="profile__image wrap">
                    <img  class="profileImg" src="{ImageProfile} " alt="profile" />
            </div>
            <!-- <h2 class="">About me</h2> -->
            <h2 class="wrap">I'm Gofran</h2>
            <p class="paragraph wrap">
                I am a passionate and skilled frontend developer with a keen focus on React and a
                flair for user interface design. My journey in web development is marked by a
                dedication to creating maintainable, scalable, and performant code, coupled with a
                commitment to delivering exceptional user experiences. My expertise extends across
                various programming languages and technologies, enhanced by a deep understanding of
                both frontend and backend development.
            </p>
            <hr class="line" />

            <p class="paragraph wrap">
                I have a broad skillset beside web-development including graphic design, user
                interface design, typography, photo and video editing. In my spare time I also enjoy
                photography and exploring the city and the nature.
            </p>
        </div>
       
        <div class="col">
            <h2 class="wrap">Professional Experience</h2>
            <h3 class="wrap">Full-Stack Developer at Green Car Lane</h3>
            <ul>
                <li class="wrap">
                    Developed version 1 of the web application, leading efforts in securing data
                    flow, strengthening the backend and database.
                </li>
                <li class="wrap">
                    Collaborated on a SaaS marketplace, working extensively with APIs and Data
                    Science.
                </li>
                <li class="wrap">
                    Utilized skills in MERN Stack, MongoDB, Git, Front-End Development, React.js,
                    and Node.js.
                </li>
            </ul>
            <hr class="line" />

            <h3 class="wrap">Front-End Developer at The Royal Danish Academy, Denmark</h3>
            <ul>
                <li class="wrap">
                    Developed a web application featuring interactive games for facial exercises,
                    leveraging machine learning technologies to enhance user engagement and
                    experience.
                </li>
            </ul>
            <hr class="line" />

            <h3 class="wrap">Frontend Developer at ADORNO, Denmark</h3>
            <ul>
                <li class="wrap">
                    Enhanced website features using WordPress, focusing on improving user experience
                    and website functionality.
                </li>
            </ul>
            <hr class="line" />
        </div>

        <!-- <p class="paragraph wrap">
        I am excited about modern, open web standards. These days I mostly do React and NodeJS apps
        with SCSS, Styled Components and CSS Module.
    </p>
    <p class="paragraph wrap">
        As a developer I focus on quality and like giving attention to detail. Code should be
        maintainable, scalable, performant and well tested. UX should feel right and look good.
    </p>

    <p class=" paragraph wrap">
        I’m a skilled and diligent frontend developer with a passion for React and a flair for user
        interface design. I have experience with different programming languages and technologies,
        including HTML5, CSS3, SASS, JavaScript, Git, React/Nextjs, Node.js, Express.js,
        MySQL and Git (Version control) as well as WordPress. I’m also very skilled in using Adobe
        design programs, such as Photoshop, Illustrator, InDesign, Lightroom, Premiere Pro, After
        Effects and 3Ds max.
    </p> -->

        <!-- <p class="paragraph styled-paragraph wrap">
        Correctly working at
        <a href=" https://kglakademi.dk/" target="_blank">
            <samp class="samp"> Green Car Lane</samp>
        </a>
       as a full-stack Developer, I drove the development of version 1 of the web application. 
        My responsibilities included securing data flow, strengthening the backend and database, and collaborating on a SaaS marketplace, all while working with APIs and Data Science. My skill set encompasses MERN Stack, MongoDB, Git, Front-End Development, React.js, and Node.js.
    </p> -->

        <!-- <p class="paragraph wrap">
        I’m educated in computer science and multimedia, and have completed a full-stack web
        development Bootcamp in Hack Your Future education program, and have achieved a lot of
        learning-by-doing through the years.
    </p>

    <p class="paragraph wrap">
        I have a broad skillset beside web-development including graphic design, user interface
        design, typography, photo and video editing. In my spare time I also enjoy photography and
        exploring the city and the nature.
    </p> -->
    </article>

    <!-- <div class="skills-overlap">
        <div class="container  overlapsk"><h1>React.js</h1></div>
        <div class="container  overlapsk"><h1>Node.js</h1></div>
        <div class="container  overlapsk"><h1>C#.js</h1></div>
        <div class="container  overlapsk"><h1>Typescript</h1></div>
        <div class="container  overlapsk"><h1>React.js</h1></div>
        <div class="container  overlapsk"><h1>React.js</h1></div>
        
    </div> -->
    <div class="home">
        <div class="racesWrapper" bind:this="{races}">
            <div class="races">
                <a
                href="https://www.linkedin.com/in/ghofranebenhmaid/"
                target="_blank"
                rel="noreferrer"
            >
    
                    <h2>Linkedin</h2>
            </a>
                <a
                    href="https://www.linkedin.com/in/ghofranebenhmaid/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div class="box">
                        <LinkedinIcon />
                    </div>
                </a>
    
                <a href="https://github.com/ghofranebenhmaid" target="_blank" rel="noreferrer">
                    <div class="box"><GithubIcon /></div>
                </a>
                <a href="https://github.com/ghofranebenhmaid" target="_blank" rel="noreferrer">
    
                    <h2>Github</h2>
                </a>
                <a href="https://github.com/ghofranebenhmaid" target="_blank" rel="noreferrer">
                    <div class="box"><InstagramIcon /></div>
                </a>
                <a href="https://instagram.com/_ghofranebenhmaid" target="_blank" rel="noreferrer">
                    <h2>Instagram</h2>
                </a>
    
            </div>
        </div>
        </div>

    <article class="container-900 m-top">
        <h2 class="">Tech Stack</h2>

        <Accordion />
    </article>



    <div class="project-overlap">
        <article class="container m-top overlap">
            <section class="project">
                <div class="project__content">
                    <div class="project1__content--image ">
                        <div class="project1__content--image-relative    ">
                            {#each [carouselPhotos1[index]] as src (index)}
                                <img
                                    src="{src}"
                                    alt="Face Garden game"
                                    in:fade="{{duration: duration / 2}}"
                                    out:fade="{{duration: duration / 2}}"
                                />
                            {/each}
                        </div>
                    </div>
                    <div class="project__content--description">
                        <h3>Face Garden</h3>
                        <p>
                            Face Garden combines machine learning and motion graphics to create
                            three games that react to facial expressions. Face Garden demonstrates
                            novel interactions between facial expressions and visual stimuli. The
                            project is a proof of concept for types of digital interactions that can
                            be used to stimulate muscular movements.
                            <a href="https://facegarden.dk/" target="_blank"
                                ><samp class="samp">facegarden.dk</samp></a
                            >
                        </p>
                        <div class="technologi">
                            <p>Reactjs/Sveltejs</p>
                            <p>UI/UX Design</p>
                            <p>AWS</p>
                            <p>LottieFiles</p>
                        </div>
                    </div>
                </div>
            </section>
        </article>

        <article class="container m-top overlap">
            <section class="project">
                <div class="project__content">
                    <div class="project__content--image ">
                        <div class="project__content--image-relative slider  ">
                            {#each [carouselPhotos[index]] as src (index)}
                                <img
                                    src="{src}"
                                    alt="Treasure Hunt game"
                                    in:fade="{{duration: duration / 2}}"
                                    out:fade="{{duration: duration / 2}}"
                                />
                            {/each}
                        </div>
                    </div>
                    <div class="project__content--description ">
                        <h3 class="">Treasure Hunt game</h3>
                        <p class="">
                            Treasure hunt game for kids parties, where an adult (host) can create a
                            game for the kids, with a theme. The host can create posts, and
                            questions. The kids have to find the posts and answer the questions.
                        </p>
                        <div class="technologi">
                            <p>Reactjs</p>
                            <p>UI/UX Design</p>
                            <p>Figma</p>
                            <p>Mongodb</p>
                        </div>
                    </div>
                </div>
            </section>
        </article>
        <div class="flex flex-jc-c flex-ai-c ">
            <a data-sveltekit-reload href="/#/projects">
                <button class="primary-button"> See More</button>
            </a>
        </div>
        
    </div>

   

    <!-- <div>
        <Slides/>
    </div> -->
    <!-- <article class=" container m-top">
    <p class="paragraph ">
        I've been working at
        <a href=" https://kglakademi.dk/" target="_blank">
            <samp class="samp"> The Royal Danish Academy (KADK)</samp>
        </a>
        as a Frontend developer using Reactjs, NodeJS, AWS, animatione Lottie, Git and Docker on a project
        called Facegarden, which is a browser game controlled with facial expressions. It is built for
        people who suffer from facial paralysis and would benefit from exercise of their facial muscles.
    </p>
   
</article> -->

    <article class="card container m-top fadein">
        <div class="card__content ">
            <h2 class="card__title">Let's build your idea.</h2>
            <p class="card__subtitle">
                If you have a project that you'd like to work together on, don't hesitate to get in
                touch.
            </p>
            <a href="mailto:me@ghofranebenhmaid.com" target="_blank">
                <samp class="samp card__email"> me@ghofranebenhmaid.com</samp>
            </a>
        </div>
    </article>

