<style lang="scss">
@import "../../styles/globals.scss";
:global(body) {
    h1,
    p {
        color: rgb(255, 255, 255);
        // overflow: hidden;
    }
}

:global(body.dark-mode) h1,
p {
    color: rgb(0, 0, 0);
    // overflow: hidden;
}

.head {
    width: 100%;
    height: 200vh;
    height: 200dvh;
    height: 200svh;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    // justify-content: space-between;
    background-color: transparent;
    h1 {

        font-size: clamp(3.5rem, min(15.5vw, 25vh), 11rem);
        // line-height: clamp(4rem, min(12.5vw, 20vh), 10rem);
        font-family: 'Clash Display', sans-serif;
        text-align: left;
        font-weight:bolder;
        line-height: clamp(3.5rem, min(15.5vw, 20vh), 10.75rem);
        // display: inline-block;
        // text-wrap: balance;
        transition: all 2.3s cubic-bezier(2.24, 0, 2.4, 1);


        p{
        font-weight:400;
font-family: 'Telma', cursive;
// font-size: clamp(2rem, min(10.5vw, 20vh), 8rem);
color: tomato;
overflow: hidden;
padding: 0 1rem ;



        }
    
        & span{
            overflow: hidden;

    transition: all 0.53s cubic-bezier(0.24, 0, .3, 1);
            cursor: default;
            &:hover{

                font-weight: 500;
            }

    }

    }
}

svg {
    position: absolute;
    inset: 0;
    height: 200vh;
    height: 200dvh;
    height: 200svh;
    z-index: -100;

}

.header__subtitle {
    font-size: clamp(1.75rem, 4.5vw, 3rem);
    font-weight: 400;
    line-height: 1.5;
    
    
    color: rgb(255, 255, 255) !important;
    strong {
        padding: 30px 0 8px;
        position: relative;
        // text-decoration: underline;
        &::before {
            content: "";
            position: absolute;
            bottom: 4px;
            width: 100%;
            height: 20px;
            //   transform: skew(-12deg) translateX(-50%);
            background: rgba(247, 64, 18, 0.911);
            z-index: -1;
        }
    }
}
</style>

<script>
import {onMount} from "svelte"
import SplitText from "../SplitText.svelte"

import {GsapHomeAnimation} from "../GsapHomeAnimation.svelte"
import {GsapAnimation} from "../GsapAnimations.svelte"
import AnimatedText from "../AnimatedText.svelte"
import CircularTextSvg from "../CircularTextSvg.svelte"
import ArrowSvg from "../ArrowSvg.svelte"

onMount(() => {
    GsapHomeAnimation()
    GsapAnimation()
})
</script>

<header>
    <div class="head container">
        <section class="head-title">
            <SplitText>
                <h1 class="head__text ">  <span>Code</span>  <br /> <span>Clarity</span> <p>&</p> <br /> <span>Creativity</span> </h1>
            </SplitText>
            <CircularTextSvg />
        </section>

        <section class="head-subtitle color-dark">
            <!-- <p class="header__subtitle fadein">
                Build websites clearly <strong>communicates</strong> with visitors. <br /> I use
                ReactJS to build <strong>awesome </strong> applications,<br /> incorporating
                <strong> efficient</strong>
                and <strong>scalable </strong> solutions.
            </p> -->

            <AnimatedText />
        </section>
    </div>

    <svg id="patternId" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
        ><defs
            ><pattern
                id="a"
                patternUnits="userSpaceOnUse"
                width="40"
                height="25"
                patternTransform="scale(1.5) rotate(90)"
                ><rect x="0" y="0" width="100%" height="100%" fill="hsla(0, 0%, 100%, .03)"
                ></rect><path
                    d="M-10 5l20 10L30 5l20 10"
                    class="path"
                    stroke-linecap="square"
                    stroke-width="0.5"
                    stroke="rgb(111, 76, 238)"
                    fill="none"></path></pattern
            ></defs
        ><rect width="800%" height="800%" transform="translate(0,0)" fill="url(#a)"></rect></svg
    >
</header>
