<style global>
@import "../styles/globals.scss";

	:global(body)  svg {
		fill: rgb(255, 255, 255);
}

:global(body.dark-mode) svg {
	fill: rgb(0, 0, 0);
}
	svg{
		width:clamp(4.75rem, 10.5vw, 18rem);
    height:clamp(4.75rem, 10.5vw, 18rem);
	}
</style>
<svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 20 20"
    ><path
        d="M3 16.2c-.3-.2-.6-.5-.9-.8-.1-.3-.2-.4-.4-.6-.5-.6-.8-.8-1.1-.9-.2-.1-.5-.3-.6-.5-.1-.2-.1-.5 0-.8.1-.2.3-.5.5-.6.2-.1.5-.1.8 0 .8.3 1.3.7 1.9 1.6-.1-.1.3.4.4.5.2.2.3.4.4.4.2.1.6.2 1.2.1 0-.4.1-.8.2-1.1-3-.7-4.6-2.6-4.6-6.4 0-1.2.4-2.4 1.1-3.3-.2-.8-.2-1.9.3-3.1.1-.1.1-.3.2-.4.1-.1.2-.2.4-.2H3c.9-.2 2 .1 3.5 1C7.4.9 8.3.8 9.2.8c.9 0 1.8.1 2.7.3C13.4.2 14.5-.1 15.3 0h.2c.1 0 .3.1.4.2.1.2.1.3.2.5.5 1.2.5 2.3.3 3.2.7.9 1.1 2 1.1 3.3 0 3.8-1.7 5.7-4.6 6.4.1.4.2.9.2 1.4v2.7c.2 0 .4.2.6.4.1.2.2.4.2.6 0 .2-.1.5-.2.6s-.4.3-.6.3c-1.1.2-2-.5-2-1.5V15c0-.7-.2-1.2-.4-1.4-.7-.6-.3-1.7.5-1.8 3-.3 4.3-1.5 4.3-4.7 0-1-.3-1.7-.9-2.4-.1-.1-.2-.3-.2-.5s0-.4.1-.5c.2-.4.2-1 .1-1.6-.6.1-1.3.4-2 .9-.1.1-.3.1-.4.2h-.4c-.9-.3-1.8-.4-2.6-.4-.9 0-1.8.1-2.6.4h-.4c-.2 0-.3-.1-.5-.2-.7-.5-1.3-.8-1.8-.9-.2.6-.1 1.2.1 1.6 0 .2.1.4 0 .5 0 .2-.1.4-.2.5-.6.7-.9 1.5-.9 2.4 0 3.2 1.4 4.3 4.3 4.7.8.1 1.2 1.2.5 1.8-.2.2-.4.7-.4 1.4v3.1c0 1-.8 1.7-2 1.5-.2 0-.4-.2-.6-.3-.2-.2-.2-.4-.2-.6 0-.2.1-.5.2-.6.1-.2.3-.3.6-.4v-1c-.9 0-1.7-.1-2.3-.5z"
    ></path></svg
>
